<template>
  <div id="nav">
    <router-link to="/"
      ><h3>Hoshino @{{ gid }}</h3></router-link
    >
    <!-- <el-button class="add-button">添加至桌面</el-button> -->
    <nav>
      <router-link :to="{ name: 'member', params: { gid } }">成员</router-link>
      |
      <router-link :to="{ name: 'progress', params: { gid } }"
        >进度</router-link
      >
      |
      <router-link :to="{ name: 'challenge-list', params: { gid }}"
        >明细</router-link
      >
      |
      <router-link :to="{ name: 'stat-daily', params: { gid } }"
        >统计</router-link
      >
      | <router-link :to="{ name: 'manual' }">指南</router-link> |
      <router-link :to="{ name: 'about' }">关于</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    gid: {
      type: Number,
      required: true,
    },
  },
  /*
  beforeCreate() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      const addBtn = document.querySelector('.add-button')
      this.deferredPrompt = e
      addBtn.style.display = 'block'
    })
  },
  methods: {
    a2hs() {
      // 隐藏显示 A2HS 按钮的界面
      const addBtn = document.querySelector('.add-button')
      addBtn.style.display = 'none'
      // 显示安装提示
      this.deferredPrompt.prompt()
      // 等待用户反馈
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        this.deferredPrompt = null
      })
    },
  },
  */
}
</script>

<style></style>
