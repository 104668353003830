import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      title: '星乃会战',
    },
  },
  /* Clan Manage */
  {
    path: '/member/:gid',
    name: 'member',
    component: () =>
      import(/* webpackChunkName: "member" */ '@/views/Member.vue'),
    props: true,
    meta: {
      title: '成员管理 - 星乃会战',
    },
  },
  /* Monthly Clanbattle */
  {
    path: '/progress/:gid',
    name: 'progress',
    component: () =>
      import(/* webpackChunkName: "progress" */ '@/views/Progress.vue'),
    props: true,
    meta: {
      title: '进度 - 星乃会战',
    },
  },
  {
    path: '/challenge/:gid',
    name: 'challenge-list',
    component: () =>
      import(
        /* webpackChunkName: "challenge-list" */ '@/views/ChallengeList.vue'
      ),
    props: true,
    meta: {
      title: '出刀明细 - 星乃会战',
    },
  },
  {
    path: '/stat/daily/:gid',
    name: 'stat-daily',
    component: () =>
      import(/* webpackChunkName: "stat" */ '@/views/StatDaily.vue'),
    props: true,
    meta: {
      title: '每日统计 - 星乃会战',
    },
  },
  /* Information */
  {
    path: '/manual',
    name: 'manual',
    component: () =>
      import(/* webpackChunkName: "manual" */ '@/views/Manual.vue'),
    meta: {
      title: '指南 - 星乃会战',
    },
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于 - 星乃会战',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
