<template>
  <NavBar :gid="gid" />
  <router-view @change-gid="setGid" />
  <div id="bg-wrapper">
    <img id="bg-img" :src="`/img/bg/${bg}`" />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      gid: 791228255,
      bg: null,
      bgs: [
        '102001101.webp',
        '102301102.webp',
        '106400401.webp',
        '106400601.webp',
        '111000102.webp',
        '114100402.webp',
        '115000101.webp',
        '115700302.webp',
        '115700401.webp',
        '115800101.webp',
        '115800401.webp',
        '180600401.webp',
        '201300902.webp',
        '210301101.webp',
        '210301801.webp',
        '210500401.webp',
        '210500902.webp',
        '210600501.webp',
        '500200701.webp',
        '500400702.webp',
        '502500401.webp',
        '502500801.webp',
        '503300801.webp',
        '503800201.webp',
        '504000501.webp',
        '504800601.webp',
        '505000801.webp',
        '505200201_20201031_1424.webp',
        '505600001.webp',
        '505600701.webp',
        '505800001.webp',
        '506100301.webp',
        '506100401.webp',
        '506400701.webp',
        '700400501.webp',
        '9000080.webp',
        '9000085.webp',
        '9000086.webp',
        '9000087.webp',
        '9000088.webp',
        '9000089.webp',
        '9000090.webp',
      ],
    }
  },
  created() {
    this.bg = this.bgs[Math.floor(Math.random() * this.bgs.length)]
  },
  methods: {
    setGid(gid) {
      this.gid = gid
    },
  },
}
</script>

<style>
body {
  margin: 0;
  height: 100%;
  background: url('/img/bg/bg1.gif') repeat center top;
}

#bg-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -100;
}

#bg-img {
  object-fit: cover;
  width: calc(100% + 2em);
  height: calc(100% + 2em);
  margin: -1em;
  filter: blur(0.618em) brightness(61.8%);
}

#app {
  font-family: Roboto, SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica,
    Arial, Microsoft YaHei UI, Microsoft YaHei, Hiragino Sans GB, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: aliceblue;
  min-height: 100vh;
  height: 100%;
}

#nav {
  padding: 0.5em;
}

#nav a {
  font-weight: bold;
  color: aliceblue;
}

#nav a.router-link-exact-active {
  color: #5ac9eb;
}

.el-table__expanded-cell[class*=cell] {
  padding: 0 !important;
}
</style>
